/* global google */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

import Swiper, {
  Navigation,
  Pagination,
  HashNavigation,
  EffectFade,
  Controller,
} from 'swiper';
Swiper.use([Navigation, Pagination, HashNavigation, EffectFade, Controller]);
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /*
   * Add active element for Condition Link
   */
  let linkItem = $(
    '.conditions-row__link[href="' + window.location.href + '"]'
  );
  $(linkItem).addClass('active');
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.reviews-slide').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('#teamCarousel').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  let initialSlide = 0;
  if (window.location.hash) {
    initialSlide = parseInt(
      $('[data-title="' + window.location.hash.replace('#', '') + '"]').data(
        'index'
      )
    );
  }

  const $photoTeamSlider = $('.slider-team-photo').slick({
    // infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide,
    cssEase: 'linear',
    dots: false,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    variableWidth: true,
    asNavFor: '.slider-team-info',
  });

  $photoTeamSlider.on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      window.location.hash = $(
        '.team-carousel__item[data-index="' + nextSlide + '"]'
      ).data('title');
    }
  );

  $photoTeamSlider.on(
    'beforeChange',
    function (event, { slideCount: count }, currentSlide, nextSlide) {
      let selectors = [nextSlide, nextSlide - count, nextSlide + count]
        .map((n) => `[data-slick-index="${n}"]`)
        .join(', ');
      $('.slick-now').removeClass('slick-now');
      $(selectors).addClass('slick-now');
    }
  );
  $('[data-slick-index="0"]').addClass('slick-now');

  $('.slider-team-info').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    draggable: false,
    arrows: false,
    fade: true,
    adaptiveHeight: true,
    asNavFor: false,
  });

  // Swiper JS
  const swiper = new Swiper('.swiper-team-photo', {
    slidesPerView: 5,
    centeredSlides: true,
    initialSlide: 2,
    spaceBetween: 30,
    slideToClickedSlide: true,
    hashNavigation: {
      watchState: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: false,
    breakpoints: {
      1200: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 3,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  });

  var swiper2 = new Swiper('.swiper-team-info', {
    autoHeight: true,
    spaceBetween: 0,
    effect: 'fade',
    initialSlide: 2,
    allowTouchMove: false,
    fadeEffect: {
      crossFade: true,
    },
    thumbs: {
      swiper: swiper,
    },
  });

  // swiper.on('click', function () {
  //   const clickSlide = this.clickedIndex;
  //   this.slideTo(clickSlide);
  // });

  swiper.on('activeIndexChange', function () {
    const activeSlideItem = this.activeIndex;
    swiper2.slideTo(activeSlideItem);
    // console.log(activeSlideItem);
  });
  // END Swiper JS
});
/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

(function ($) {
  // ACF Google Map JS code
  function render_map($el) {
    var $markers = $el.find('.marker');
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
    };
    var map = new google.maps.Map($el[0], args);

    map.markers = [];

    $markers.each(function () {
      add_marker($(this), map);
    });

    center_map(map);
  }

  var infowindow;
  function add_marker($marker, map) {
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
    });

    map.markers.push(marker);

    if ($.trim($marker.html())) {
      infowindow = new google.maps.InfoWindow();

      // google.maps.event.addListener(marker, 'click', function () {
      //   infowindow.close();
      //   infowindow.setContent($marker.html());
      //   infowindow.open(map, marker);
      // });

      google.maps.event.addListener(marker, 'mouseover', function () {
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  function center_map(map) {
    var bounds = new google.maps.LatLngBounds();

    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());
    } else {
      map.fitBounds(bounds);
    }
  }

  $(document).ready(function () {
    $('.acf-map').each(function () {
      render_map($(this));
    });
  });
})(jQuery);
